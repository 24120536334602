import React, {  memo,  useState } from "react";

import {  graphql,  useStaticQuery } from "gatsby";
import {  formium } from "@helper/formium";

// Component imports
import {  Section,  Text,  Button } from "@utils";
import {  FormiumForm,  defaultComponents } from "@formium/react";
import SEO from "@component/SEO";
import Reveal from "@component/globals/Reveal";

// Components

const FormControl = memo(function FormControl({
	children,
	description,
	error,
	label,
	labelFor,
}: any) {
	return (
		<div className="flex flex-col mb-5 space-y-5">
			{label && (
				<label htmlFor={labelFor}>
					<Text style="h6" tag="p">
						{label}
					</Text>
				</label>
			)}
			{description && <div>{description}</div>}
			{children}
			{error && (
				<div>
					<p className="text-sm font-semibold text-red-500">{error}</p>
				</div>
			)}
		</div>
	);
});

const TextInput = memo(function TextInput(props) {
	return (
		<input
			className="rounded-2xl focus:outline-none focus:ring-4 focus:ring-coral focus:ring-opacity-30 ease-inOutQuint p-4 transition-all duration-500 bg-gray-900"
			{...props}
		/>
	);
});

const Textarea = memo(function TextInput(props) {
	return (
		<textarea
			className="rounded-2xl lg:h-56 focus:outline-none focus:ring-4 focus:ring-coral focus:ring-opacity-30 ease-inOutQuint h-48 p-4 transition-all duration-500 bg-gray-900 resize-none"
			{...props}
		/>
	);
});

const SubmitButton = memo(function SubmitButton(props) {
	return (
		<div className="flex justify-center">
			<button className="primary" {...props}>
				Submit
			</button>
		</div>
	);
});

const components = {
	FormControl,
	TextInput,
	Textarea,
	SubmitButton,
};

const Contact: React.FC = () => {
	// Queries
	// const data = useStaticQuery(graphql`
	// 	{
	// 		formiumForm(slug: { eq: "coraldev-contact-form" }) {
	// 			schema
	// 		}
	// 	}
	// `);

	// State
	const [success, setSuccess] = useState(false);

	return (
		<>
			<SEO
				title="Contact"
				path="/contact"
				description="Let's work together on your next big project. Get in touch with our team today!"
			/>
			<Section id="contact">
				<div className="lg:space-y-0 lg:flex lg:items-center xl:space-x-32 lg:justify-between lg:space-x-16 space-y-16">
					<div className="lg:w-1/2 space-y-8">
						<Reveal>
							<Text style="h2" tag="h1">
								<span className="bg-clip-text bg-gradient-to-r from-coral to-reef text-transparent">
									Let's Work Together On Your Next Big Project!
								</span>
							</Text>
							<Text>
								We're always up to work with clients bringing new opportunities
								to the Minecraft scene. Our ideal clients are those just
								starting up their servers and looking for a developer(s) to help
								make their server a reality!
							</Text>

							<Text>
								Contact us for a free estimate on your project! We recommend you
								create a ticket in our Discord server but you can also email us
								using our contact form. We look forward to working with you!
							</Text>
							<Button
								type="external"
								variant="primary"
								href="https://discord.coraldev.net"
							>
								Join Our Discord Server
							</Button>
						</Reveal>
					</div>
					<div className="rounded-2xl lg:w-1/2 lg:p-10 p-6 bg-gray-700">
						{/* <FormiumForm
              data={data.formiumForm}
              components={{ ...defaultComponents, ...components }}
              onSubmit={async (values) => {
                // Send form values to Formium
                await formium.submitForm('coraldev-contact-form', values);
                setSuccess(true);
              }}
            /> */}

						{success && (
							<div className="mt-6 text-center">
								<Text style="h5" tag="p">
									Message Sent! We'll reply within 1-2 business days.
								</Text>
							</div>
						)}
					</div>
				</div>
			</Section>
		</>
	);
};

export default Contact;
